import React, { useEffect } from 'react';
import styles from './AboutUs.module.css';

const AboutUs = () => {
  const handleClick = () => {
    window.open('https://wa.me/+201050511086', '_blank'); 

  };

useEffect(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

 
}, [])



  return (
    <>
      <section id='aboutus' className={`${styles.AboutUs} sec1`}>
        <div className="title text-center d-flex justify-content-center flex-column align-items-center pt-5">
          <h3 className='textmain pt-5'>الفيديو التعريفي</h3>
          <div className="video my-5">
            <video  controls>
              <source src="/path/to/your/video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={`${styles.bgsec} sec textmain d-flex justify-content-center align-items-center rounded-2 px-4 py-3`}>
            <button onClick={handleClick} className=' bg-transparent fs-3 text-white border-0'>احجز معانا دلوقتي <i className="fa-brands fa-whatsapp"></i></button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
