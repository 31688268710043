import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LayOut from './Components/LayOut/LayOut';
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/AboutUs';
import Stages from './Components/Stages/Stages';
import Login from './Components/Login/Login';
import NotFound from './Components/NotFound/NotFound';
import Courses from './Components/Courses/Courses';
import Lessons from './Components/Lessons/Lessons';
import Modal from './Components/Modal/Modal';
import TokenContextProvider from './Context/Token';
import ProtectedRoutes from './Components/ProtectedRoutes/ProtectedRoutes';
import ProtectedRoutesX from './Components/ProtectedRoutesX/ProtectedRoutesX';
import SubCategory from './Components/SubCategory/SubCategory';
import OurLessons from './Components/OurLessons/OurLessons';
function App() {
  const routes = createBrowserRouter([
    {
      path: "",
      element: <LayOut />,
      children: [
        { path: "/", element: <Home /> },
        { path: "home", element: <Home /> },
        { path: "aboutus", element:<AboutUs />  },
        { path: "stages", element:<Stages /> },
        { path: "subcategory/:id", element:<SubCategory /> },
        { path: "ourlessons/:id", element:<OurLessons /> },

        { path: "courses/:id", element:<ProtectedRoutes> <Courses /></ProtectedRoutes> },
        { path: "ourlessons/:id", element:<ProtectedRoutes><OurLessons /></ProtectedRoutes> },

        { path: "lessons/:id", element:<ProtectedRoutes> <Lessons /></ProtectedRoutes> },
        { path: "modal", element:<ProtectedRoutes><Modal /></ProtectedRoutes>  },
        { path: "login", element:<ProtectedRoutesX><Login /></ProtectedRoutesX> },

        { path: "*", element: <NotFound /> }
      ]
    }
  ]);

  return (
    <>
    <TokenContextProvider>
    <RouterProvider router={routes}></RouterProvider>

    </TokenContextProvider>
    </>
  );
}

export default App;
