import React, { useContext, useEffect } from 'react';
import styles from './NavBar.module.css';
import logo from '../../Assets/images/logo.jpg'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { TokenContext } from '../../Context/Token';

const NavBar = () => {
  let navigate=useNavigate()
  let { token, setToken } = useContext(TokenContext)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  function LogOut() {
    localStorage.removeItem("userToken")
    setToken(null)
    navigate("/login")
  }

  return(<>
 <nav className="navbar navbar-expand-lg  navbar-dark bgnav navfont fixed-top" dir='rtl'>
  <div className="container">
    <NavLink className="navbar-brand p-0 m-0" onClick={scrollToTop} to={'home'}>
      <img src={logo}  height={60} width={90} alt="" />
   
    </NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
       <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink className="nav-link textmain ms-4"  activeClassName="active" onClick={scrollToTop} aria-current="page" to={'home'}>الرئيسية </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link textmain"  activeClassName="active" onClick={scrollToTop} to={'aboutus'}>        حول المنصة           </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link textmain ms-4"  activeClassName="active" onClick={scrollToTop}  to={'stages'}>المراحل الدراسية </NavLink>
        </li>
       
       
      </ul>
     
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        {token?
        <>
          <li className="nav-item">
          <button className={`${styles.bglogin} nav-link textmain  rounded rounded-3 px-3 py-2`} onClick={LogOut}>  تسجيل خروج          </button>
        </li>
        </>:<>
        
        <li className="nav-item">
          <NavLink className={`${styles.bglogin} nav-link textmain  rounded rounded-3 px-3  py-2`} to={'login'}>  تسجيل الدخول           </NavLink>
        </li>
        </>}
    
      
        </ul>

    </div>
  </div>
</nav>


  </>)
};

export default NavBar;
