import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Login.module.css'
import logo from '../../Assets/images/logo.jpg';
import axios from 'axios';
import { TokenContext } from '../../Context/Token';


const Login = () => {
  const [errMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let {setToken}=useContext(TokenContext)
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required("Email is required"),
    password: Yup.string().matches(/^[a-z0-9]{3,8}$/, "Password must be 6-9 characters, start with a letter, and contain only letters and numbers").required("Password is required"),
  });

  async function callLogin(reqBody) {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const { data } = await axios.post(`https://el-manasa.mediagrafico.com/api/login`, reqBody);
      console.log(data);
      if (data.message === "Logged in successfully") {
        localStorage.setItem("userToken" ,data.access_token)
        setToken(data.access_token)
        navigate("/home");
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(err.response.data.message);
    }
  }

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: callLogin
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (<>
    <Helmet>
      <title>تسجيل الدخول</title>
    </Helmet>
    <section className={styles.login} id='login'>
      <div className={styles.container}>
        <h5 className={styles.title}>
          Welcome to <img src={logo} className='w-25 d-block ms-auto rounded-2' alt="logo" />
        </h5>
        <h2 className='text-end pe-2 pb-1'>تسجيل الدخول</h2>
        {errMessage ? <div className=' alert alert-danger'>{errMessage}</div> : null}
        <form className='d-flex text-end flex-column' onSubmit={loginForm.handleSubmit}>
          <div className="form-group my-3">
            
          <label className='  ' htmlFor="email">البريد الالكتروني أو اسم المستخدم</label>
          <input placeholder='Username or email address' type="email" id="email" name="email" onBlur={loginForm.handleBlur} className="form-control mb-3" value={loginForm.values.email} onChange={loginForm.handleChange} />
            {loginForm.errors.email && loginForm.touched.email ? <div className=' alert alert-danger'>{loginForm.errors.email}</div> : null}
          </div>
          <div className="form-group my-3">
          <label className='text-end' htmlFor="password">ادخل كلمة السر</label>
          <input placeholder='Password' type="password" id="password" onBlur={loginForm.handleBlur} name="password" className="form-control mb-3" value={loginForm.values.password} onChange={loginForm.handleChange} />
            {loginForm.errors.password && loginForm.touched.password ? <div className=' alert alert-danger'>{loginForm.errors.password}</div> : null}
          </div>
          <button type="submit" className={styles.button} disabled={!(loginForm.isValid && loginForm.dirty)}>
            {isLoading ? <i className=' fa fa-spinner fa-spin'></i> : 'تسجيل الدخول'}
          </button>
        </form>
      </div>
    </section>
  </>

  );
};

export default Login;