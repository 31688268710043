import React, { useState, useEffect, useContext } from 'react';
import styles from './Stages.module.css';
import { Link } from 'react-router-dom';
import { ContentContext } from '../../Context/ContentContext';
import Loader from '../Loader/Loader';

const Stages = () => {

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const { getAllCategories } = useContext(ContentContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    async function fetchCategories() {
      try {
        const data = await getAllCategories();
        setCategories(data.categories || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    }

    fetchCategories();
  }, [getAllCategories]);

  return (
    <section id="stages">
      <div className={`${styles.stages} sec2 text-center`}>
        <div className="container">
          <div className="title">
            <h3 className="text-dark">المراحل الدراسية</h3>
          </div>
          {loading ? (
           <Loader/>
          ) : (
            <div className="row pt-4 mt-5">
              {categories.map((category) => (
                <div key={category.id} className="col-12 col-md-6 col-lg-4 mb-4">
                  <Link className="text-decoration-none" to={`/subcategory/${category.id}`}>
                  
                    <div className={styles.one}>
                      <h5 className="textmain pt-4">{category.name}</h5>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Stages;
