import React, { useContext, useState } from 'react';
import styles from './Home.module.css';
import rocket from '../../Assets/images/63d87e1c432c751c8c28876670d52e34.png';
import AboutUs from '../AboutUs/AboutUs';
import Stages from '../Stages/Stages';
import { TokenContext } from '../../Context/Token';

const Home = () => {
  // const { token, setToken } = useContext(TokenContext);
  // const [showModal, setShowModal] = useState(true); // Initial state to show the modal

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  return (
    <>
      
        <section className={styles.home}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 text-end text-md-end text-center">
            <h2 className="pt-5 mt-5 textmain">
              مرحباً بكم فى أكبر <span className={`h2 ${styles.colorthird}`}>منصة تعليمية</span>
            </h2>
            <h2 className="textmain">
              لمادة اللغة الانجليزية مع كبير متدربي المادة
            </h2>
            <h2 className={styles.colorthird}>
              مستر / محمد عيد
            </h2>
          </div>
          <div className="col-md-4 text-center text-md-start">
            <img src={rocket} className="w-50 pt-5 mt-2" alt="Rocket" />
          </div>
        </div>
      </div>
    </section>
    <AboutUs />
    <Stages />
    
    
   
      </>

      
      
      
    
  );
};

export default Home;
